<template>
  <div class="mobile-app" :class="{ mobile: isPhone }">
    <Hero heading="The Electrify America Mobile App">
      <template #image>
        <picture>
          <source
            srcset="@/assets/images/Mobile-App/hero-desktop.png"
            media="(min-width: 750px)"
            alt="A completely redesigned experience"
          />
          <source alt="A completely redesigned experience" srcset="@/assets/images/Mobile-App/Mobile/hero.png" />
          <img src="@/assets/images/Mobile-App/Mobile/hero.png" alt="A completely redesigned experience" />
        </picture>
      </template>
      <h2 class="hero-title headline3">Easy charging on the go</h2>
      <div class="hero-content">
        <p>
          Use the Electrify America mobile app to find a public charging station. Sign up for an account to enjoy
          exclusive, members-only public charging features and pricing.
        </p>
        <div class="inline">
          <a href="https://itunes.apple.com/us/app/electrify-america/id1458030456?mt=8" rel="noopener" target="_blank">
            <img
              loading="lazy"
              :src="require('@/assets/images/Global/app-store-logo@2x.webp')"
              alt="Download on the App Store"
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.ea.evowner&rdid=com.ea.evowner"
            rel="noopener"
            target="_blank"
          >
            <img
              loading="lazy"
              :src="require('@/assets/images/Global/google-play-badge@2x.webp')"
              alt="Get it on Google Play"
            />
          </a>
        </div>
      </div>
    </Hero>
    <section class="video">
      <div class="flex container">
        <youtube height="442" width="100%" :video-id="videoId" ref="youtube" @playing="playing"></youtube>
        <div id="live-region" aria-live="assertive" class="sr-only"></div>
      </div>
    </section>
    <section class="locate-charger">
      <Card
        heading="Locate a public charger"
        headlineLevel="h2"
        headlineDisplayClass="headline3"
        isReverse
        :maxWidth="1037"
      >
        <template #image>
          <img
            loading="lazy"
            :src="require('@/assets/images/Mobile-App/locate_a_charger.png')"
            alt="Mobile app screen displaying the ability to location and get directions to chargers"
          />
        </template>
        <p>Open the app to quickly find the Electrify America charging stations nearest to you.</p>
        <p>
          You can also see the number of chargers of each type (CCS, CHAdeMO, and Level 2) at the station. Become a
          member to see real-time charger availability and receive an alert when an occupied charger becomes available.
        </p>
      </Card>
    </section>
    <section class="app-features">
      <div class="l-three-col">
        <Card
          heading="Contactless payment"
          headlineLevel="h2"
          isSmall
          :isReverse="isPhone"
          headlineDisplayClass="headline4"
        >
          <template #image>
            <img
              loading="lazy"
              src="@/assets/images/Mobile-App/contactless_payment@2x.png"
              alt="Contactless payment within the app"
            />
          </template>
          <p>
            Sign up for a membership in the app and pay for your charge by simply tapping the charger or swiping your
            phone’s screen.
          </p>
        </Card>
        <Card
          heading="Track your progress"
          alt=""
          headlineLevel="h2"
          isSmall
          :isReverse="isPhone"
          headlineDisplayClass="headline4"
        >
          <template #image>
            <img
              loading="lazy"
              :src="
                isPhone
                  ? require('@/assets/images/Mobile-App/Mobile/progress_mobile@2x.png')
                  : require('@/assets/images/Mobile-App/progress@2x.png')
              "
              alt="View your charging history within mobile app"
            />
          </template>
          <p>
            For compatible EVs, the app shows your
            <router-link class="link" :to="{ name: 'glossary-en' }">state of charge (SOC)</router-link> and sends you
            custom notifications about your session.
          </p>
        </Card>
        <Card
          heading="See your charge history"
          headlineLevel="h2"
          isSmall
          :isReverse="isPhone"
          headlineDisplayClass="headline4"
        >
          <template #image>
            <img
              loading="lazy"
              src="@/assets/images/Mobile-App/Mobile/charge_history@2x.png"
              alt="View your charging history within mobile app"
            />
          </template>
          <p>Keep track of your public charging sessions, including energy delivered and start times.</p>
        </Card>
      </div>
      <div class="carplay-container lazy-background">
        <Card headlineLevel="h2" heading="CarPlay and Android Auto integration" isReverse>
          <template #image>
            <img loading="lazy" src="../../assets/images/Mobile-App/carplay@2x.png" alt="Carplay Device." />
          </template>
          <p>
            Find and navigate to nearby stations on the go and start a charge remotely from your compatible vehicle’s
            in-dash system.
          </p>
        </Card>
      </div>
    </section>
    <section class="membership">
      <div class="container">
        <h2>Enjoy the benefits of membership</h2>
        <p class="tag-line">
          Download the Electrify America app and sign up to get the most out of your charging experience
        </p>
        <p class="disclaimer">Data and texting rates may apply</p>
        <div class="l-three-col">
          <div class="col">
            <div class="col-body">
              <h3>Guest Charging</h3>
              <span>Pay as you go</span>
              <decorative-list>
                <li>
                  <p>Find a charging station</p>
                </li>
                <li>
                  <p>Swipe or tap your debit or credit card to pay easily</p>
                </li>
              </decorative-list>
            </div>
          </div>
          <div class="col">
            <div class="special-header">ONLY IN THE APP</div>
            <div class="col-body">
              <h3>Pass Membership</h3>
              <span>No monthly fee</span>
              <decorative-list>
                <li>
                  <p>See real-time charger availability</p>
                </li>
                <li>
                  <p>Pay contact-free from the app</p>
                </li>
                <li>
                  <p>Favorite charging stations in the app</p>
                </li>
                <li>
                  <p>Start charging from the app and track your progress</p>
                </li>
                <li>
                  <p>Get notified when a charger opens up or your EV reaches your desired state of charge</p>
                </li>
                <li>
                  <p>Find a charging station</p>
                </li>
                <li>
                  <p>Swipe or tap your debit or credit card to pay easily</p>
                </li>
              </decorative-list>
            </div>
          </div>
          <div class="col">
            <div class="special-header">ONLY IN THE APP</div>
            <div class="col-body">
              <h3>Pass+ Membership</h3>
              <span>$7/month</span>
              <decorative-list>
                <li class="flex-row unqiue-row">
                  <div>
                    <p>Enjoy reduced costs for charging</p>
                    <div class="flex-row">
                      <StarIcon class="image" />
                      <p class="special-list">Pass+ Members save about 25% on charging</p>
                    </div>
                  </div>
                </li>
                <li>
                  <p>Pay-contact free from the app</p>
                </li>
                <li>
                  <p>Favorite charging stations in the app</p>
                </li>
                <li>
                  <p>Start charging from the app and track your progress</p>
                </li>
                <li>
                  <p>Get notified when a charger opens up or your EV reaches your desired state of charge</p>
                </li>
                <li>
                  <p>Find a charging station</p>
                </li>
                <li>
                  <p>Swipe or tap your debit or credit card to pay easily</p>
                </li>
              </decorative-list>
            </div>
          </div>
        </div>
        <div class="flex-column">
          <p>Pricing for DC fast charging varies by location.</p>
          <router-link :to="{ name: 'pricing-en' }" class="button">See Pricing Details</router-link>
        </div>
      </div>
    </section>
    <section class="faq">
      <MobileAppFaq />
    </section>
  </div>
</template>

<script>
import MobileAppFaq from '@/components/MobileAppFaq.vue';
import DecorativeList from '@/components/DecorativeList/DecorativeList.vue';
import StarIcon from '@/assets/images/Mobile-App/StarIcon.svg';
import Hero from '@/components/Hero/Hero.vue';
import Card from '@/components/Card/Card.vue';

export default {
  name: 'mobile-app',
  jsonld: {
    '@context': 'https://schema.org',
    '@type': 'SoftwareApplication',
    name: 'Electrify America',
    applicationCategory: 'DriverApplication',
    installUrl:
      'https://apps.apple.com/us/app/electrify-america/id1458030456, https://play.google.com/store/apps/details?id=com.ea.evowner&hl=en_US&gl=US',
    operatingSystem: 'iOS, Android',
    aggregateRating: { '@type': 'AggregateRating', ratingValue: '3', ratingCount: '592' },
    offers: { '@type': 'Offer', price: '0.00', priceCurrency: 'USD' },
  },
  metaInfo: {
    title: 'Download our mobile app for iOS or Android | Electrify America',
    meta: [
      {
        name: 'description',
        content:
          'Manage your Electrify America charging session right from your phone. Download our app and discover a seamless way to charge your electric vehicle (EV).',
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/mobile-app/' }],
    script: [
      {
        type: 'application/ld+json',
        json: {
          '@context': 'https://schema.org',
          '@type': 'SoftwareApplication',
          name: 'Electrify America',
          applicationCategory: 'DriverApplication',
          installUrl:
            'https://apps.apple.com/us/app/electrify-america/id1458030456, https://play.google.com/store/apps/details?id=com.ea.evowner&hl=en_US&gl=US',
          operatingSystem: 'iOS, Android',
          aggregateRating: { '@type': 'AggregateRating', ratingValue: '3', ratingCount: '592' },
          offers: { '@type': 'Offer', price: '0.00', priceCurrency: 'USD' },
        },
      },
    ],
  },
  components: {
    MobileAppFaq,
    StarIcon,
    DecorativeList,
    Hero,
    Card,
  },
  data() {
    return {
      videoId: 'wE-B7Dh0raE',
    };
  },
  methods: {
    playing() {
      this.announceCaptions(
        "Today every trip gets easier with your electric vehicle. Introducing, a new Electrify America mobile experience. Designed to fit the way you drive. A new simplified sign up process. With no credit card required. Easily locate stations nearby. Start charging, with a membership plan made to fit your charging needs. Pass+ members ($7/month) save about 25% on charging. With real time station availability, start charging with confidence. Initiate charging. Mobile notification says 'Electrify America charging started Your charging session started successfully'. Track your charging status. And for your home charging needs, manage your HomeStation. All in one app."
      );
    },
    announceCaptions(message) {
      const liveRegion = document.getElementById('live-region');
      liveRegion.textContent = message;
    },
  },
  computed: {
    isPhone() {
      return this.$resize && this.$mq.below(749);
    },
    player() {
      return this.$refs.youtube.player;
    },
  },
};
</script>

<style scoped lang="scss">
.mobile-app {
  // Utility Classes
  .container {
    padding: 0 20px;
    max-width: 1200px;
    margin: 0 auto;
  }

  // Section Grouping

  .video,
  .membership {
    padding: 60px 0;
    @media (min-width: 1130px) {
      padding: 100px 0;
    }
  }

  .locate-charger,
  .membership {
    background-color: $c-secondary-background;
  }

  .locate-charger,
  .app-features,
  .membership {
    .flex {
      @media (max-width: 1030px) {
        flex-direction: column;
      }
    }
  }

  // Sections
  .app-features {
    .flex {
      align-items: center;
      @media (min-width: 750px) {
        justify-content: space-between;
      }
    }
    .col {
      flex: 1;
      margin-bottom: 24px;
      @media (min-width: 1130px) {
        margin-bottom: 0;
        margin-right: 60px;
      }

      img {
        margin-top: auto;
        width: 100%;
        height: auto;
        @media (min-width: 1130px) {
          order: 1;
          margin-top: initial;
        }
      }

      h2 {
        margin-bottom: 8px;
        font-size: 24px;
        line-height: 1;
        @media (min-width: 1130px) {
          order: 2;
          margin-top: 40px;
        }
      }

      p {
        margin-top: 0;
        margin-bottom: 24px;
        @media (min-width: 1130px) {
          order: 3;
          margin-bottom: 0;
        }
      }
    }

    .col:last-child {
      margin-right: 0;
    }
    .carplay-container {
      background-image: url('../../assets/images/Mobile-App/carplay_bg.webp');
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 8px;
      padding: 13px 30px 40px 30px;
      flex-direction: column;
      text-align: center;
      margin: 40px 20px 56px;

      @media (min-width: 750px) {
        width: 75%;
        max-width: 1160px;
        margin: 70px auto 123px auto;
        padding: 11px 80px 0px 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .card--reverse {
        padding-bottom: 0;
        @media (min-width: 750px) {
          padding-bottom: 60px;
        }
        img {
          max-width: 544px;
        }
        ::v-deep .card__headline {
          margin-bottom: 15px;
          margin-top: 0;
          color: $c-primary-background;
          font-size: 16px;
          letter-spacing: 0.32px;
          line-height: normal;
          max-width: 200px;

          @media screen and (min-width: 750px) {
            font-size: 24px;
            line-height: 36px;
            letter-spacing: 0.48px;
            max-width: 100%;
          }
        }
        ::v-deep .card__column {
          max-width: 320px;
          text-align: left;
        }
        ::v-deep .card__container {
          margin: 0;
          max-width: 1078px;
          @media (max-width: 950px) {
            flex-direction: column;
            align-items: center;
          }
        }

        p {
          margin-top: 0;
          line-height: 24px;
          font-size: 16px;
          color: $c-primary-background;
          @media screen and (max-width: 750px) {
            letter-spacing: 0.8px;
            margin-bottom: 42px;
          }
        }
      }
    }
  }

  .button {
    margin-top: 30px;
    padding: 1.28em 1.6em;
  }

  .card.card--small {
    padding-bottom: 16px;

    @media (min-width: 1030px) {
      max-width: 100%;
      padding-bottom: 60px;
      max-width: 320px;
    }
    ::v-deep .card__column {
      font-size: 16px;
      max-width: 378px;
      margin: 0;
      @media (min-width: 750px) {
        font-size: 24px;
      }
    }
    ::v-deep .card__headline.headline4 {
      font-size: 24px;
      @media (max-width: 750px) {
        font-size: 16px;
        margin: 0;
      }
    }
  }

  .inline {
    margin-top: 24px;
    img {
      margin-top: 0;
      height: 45px;
      width: auto;
      &:first-child {
        margin-right: 20px;
      }
    }
    @media (min-width: 750px) {
      height: 53px;
    }
  }

  .home {
    background-image: linear-gradient(#f5f5f5, rgba(245, 245, 245, 0));
    .home-top {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @media (max-width: 1130px) {
        flex-direction: column-reverse;
      }

      .card {
        @media (min-width: 750px) {
          padding-bottom: 0;
        }
        ::v-deep .card__container {
          max-width: 1037px;
          margin: 0;
          align-items: center;

          h2 {
            margin: 0;
            font-size: 32px;
            line-height: 37.1px;
            letter-spacing: 0.64px;
            @media (max-width: 750px) {
              font-size: 24px;
            }
          }
          p {
            color: $c-primary;
            letter-spacing: 0.48px;
            font-size: 16px;
            margin: 10px 0 12px 0;
            line-height: 24px;
            @media (min-width: 750px) {
              margin: 24px 0;
              font-size: 24px;
              line-height: 36px;
              font-weight: $fw-light;
            }
          }
          .card__column {
            max-width: 555px;
          }
        }
      }
      img {
        max-width: 420px;
        @media (max-width: 1130px) {
          width: 100%;
        }
      }
    }
    .home-bottom {
      text-align: center;
      margin-bottom: 60px;
      @media (min-width: 750px) {
        margin-bottom: 100px;
      }
      h2 {
        font-size: 24px;
        font-weight: $fw-regular;
        line-height: 36px;
        letter-spacing: 0.48px;
        margin-top: 0px;
        margin-bottom: 24px;
      }
      .button {
        border: none;
        padding: 11px 24px;
        font-size: 14px;
        line-height: 30px;
        margin-top: 0;
      }
    }
  }

  .locate-charger {
    padding: 40px 24px 0;
    .card--reverse {
      img {
        max-width: 548px;
      }
      ::v-deep .card__column {
        max-width: 378px;
      }
      ::v-deep .card__container {
        max-width: 1025px;
        align-items: center;
        @media (max-width: 950px) {
          flex-direction: column;
        }
      }
      ::v-deep .card__headline {
        margin: 0;
        margin-bottom: 16px;
        @media (min-width: 750px) {
          font-size: 32px;
        }
      }
    }

    p {
      margin-bottom: 32px;
      margin-top: 0;
    }
  }

  .membership {
    @media (min-width: 750px) {
      h2 {
        font-size: 32px;
      }
    }
    h2,
    h3 {
      margin: 0;
      margin-bottom: 16px;
      line-height: 1.4;
      font-weight: 600;
    }

    h2,
    .disclaimer,
    .tag-line {
      @media (min-width: 1130px) {
        max-width: 550px;
      }
    }

    h3 {
      margin-bottom: 6px;
    }
    .tag-line {
      font-size: 24px;
      font-weight: $fw-regular;
      line-height: 36px;
      letter-spacing: 0.48px;
      margin-bottom: 16px;
      color: $c-primary;
      @media (max-width: 750px) {
        font-size: 21px;
        line-height: 1.4;
      }
    }

    .disclaimer {
      font-size: 14px;
      color: $c-primary;
      font-weight: 600;
      margin-bottom: 60px;
      @media (min-width: 1130px) {
        font-size: 16px;
      }
    }

    .col {
      position: relative;
      flex: 1;
      margin-bottom: 50px;
      @media (min-width: 1030px) {
        margin-bottom: 0;
        margin-right: 24px;
      }
      &:last-child {
        margin-right: 0;
        margin-bottom: 0;
      }

      span {
        display: block;
        margin-bottom: 40px;
      }

      .col-body {
        position: relative;
        padding: 32px;
        border-radius: 8px;
        background-color: $c-primary-background;
        z-index: 2;
        li {
          margin-bottom: 24px;
        }
        h3 {
          font-size: 24px;
        }
      }

      .special-header {
        border-radius: 8px 8px 0 0;
        width: 100%;
        position: absolute;
        top: -28px;
        z-index: 0;
        left: 0;
        color: $c-primary-background;
        font-weight: $fw-medium;
        text-align: center;
        padding: 5px 0 10px;
        font-size: 14px;
        background-color: $c-primary-lighter;
      }

      .flex-row {
        margin-bottom: 24px;
        align-items: center;
        img {
          margin-right: 20px;
          width: 18px;
          height: 32px;
        }
        .image {
          margin-right: 12px;
          width: 35px;
          height: 35px;
        }
      }
    }

    .unqiue-row {
      align-items: initial !important;
      p {
        margin: 0;
      }
      .flex-row {
        margin-top: 12px;
        margin-bottom: 0;
        margin-left: 2.4rem;
        .special-list {
          padding-left: 0;
        }
      }
    }

    .flex-column {
      margin-top: 52px;
      align-items: center;

      p {
        color: $c-primary;
        font-size: 21px;
        margin: 0;
        @media (min-width: 1130px) {
          font-size: 24px;
        }
      }
    }
  }

  .video {
    @media (max-width: 750px) {
      iframe {
        max-width: 100%;
      }
    }

    .flex {
      justify-content: center;

      @media (min-width: 800px) {
        width: 785px;
      }

      .yt-video {
        max-width: 800px;
        height: auto;
      }
    }
  }
  .lazy-background {
    background-image: none; /* Placeholder image */
  }

  .lazy-background.visible {
    background-image: url('../../assets/images/Mobile-App/carplay_bg.webp');
  }
}
</style>
